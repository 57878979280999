<template>
  <div>
    <div :for="id" class="form-label" v-if="label" v-html="label"></div>
    <div class="holder mb-2 mb-md-0" @click.self="isOpen = !isOpen">

      <div class="gold-text medium holder_font" @click="isOpen = !isOpen">
        <span v-if="!value && placeholder" >
          {{placeholder}}
        </span>
        <span v-else-if="value" >
          {{value}}
        </span>
        <span v-else>
          Select Date
        </span>
      </div>
      <div class="dropdown_icon gold-text" @click="isOpen = !isOpen">
        <ios-arrow-down-icon />
      </div>
      <transition name="slideBottom">
        <div class="select_dropdown" :class="{up: goUp, 'with_label': label}" v-if="isOpen" @click.self="isOpen = !isOpen">
          <div class="dropdown_inner shadow">
            <div class="row mx-0 mt-2 justify-content-center scroll_inner">
              <div class="col-12 mt-3 text-center">
                <VueDatePicker v-model="value" inline auto-apply model-type="yyyy-MM-dd" :enable-time-picker="false" :month-change-on-scroll="false"></VueDatePicker>
              </div>
            </div>
            <div class="row mx-0 pb-3 justify-content-center">
              <div class="col-10">
                <Button color="green" width="100" btnText="Apply" icon="arrow" @buttonClicked="applyFilter">
                  <ios-checkmark-icon class="inline-icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import '@/assets/css/datepicker.css'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../Button.vue')),
    VueDatePicker,
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue')),
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue'))
  },
  name: 'SelectDatePicker',
  props: ['placeholder', 'options', 'label', 'modelValue', 'goUp', 'id'],
  emits: ['update:modelValue', 'apply'],
  data () {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD'
      },
      isOpen: false
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    applyFilter () {
      this.isOpen = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .holder {
    background-color: var(--green-color-dark);
    padding: 12px 20px 12px 16px;
    line-height: 1;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
  }
  .holder_font {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 13px;
    right: 16px;
    font-size: 1rem;
    z-index: 1;
  }
  .select_dropdown {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 60px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    /* background-color: pink; */
  }
  .close {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: inherit;
  }
  .dropdown_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 75vh;
    background-color: rgb(249, 250, 251);
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(51, 51, 51);
  }
  .scroll_inner {
    max-height: 350px;
    overflow-y: auto;
  }
  .form-control {
    background-color: #fff;
    color: var(--green-color);
    border-color: var(--green-color);
    border-radius: 2rem;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 500;
    text-align: left;
  }
  .slideBottom-enter-active {
    transition: all 0.5s ease;
  }
  .slideBottom-leave-active {
    transition: all 0.5s ease;
  }
  .slideBottom-enter-from {
    transform: translateY(100%);
    opacity: 1;
  }
  .slideBottom-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
  @media (min-width: 992px) {
    .select_dropdown {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100%;
      min-height: 60px;
      height: inherit;
      background-color: transparent;
    }
    .dropdown_inner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: inherit;
      max-height: inherit;
      top: 50px;
      width: max-content;
      max-width: 100%;
      background-color: rgb(249, 250, 251);
      border-radius: 20px;
      color: rgb(51, 51, 51);
    }
    .up .dropdown_inner {
      bottom: 65px;
      top: auto;
    }
    .with_label .dropdown_inner {
      top: 80px;
    }
    .dropdown_inner.full {
      width: max-content;
      min-width: inherit;
    }

    .holder_font {
      font-size: 15px;
    }
    .holder {
      height: 44px;
      padding: 13px 48px 13px 16px;
    }
    .slideBottom-enter-active {
      transition: all 0.5s ease;
    }
    .slideBottom-leave-active {
      transition: all 0.1s ease;
    }
    .slideBottom-enter-from {
      transform: translateY(0);
      opacity: 0;
    }
    .slideBottom-leave-to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
